/* @import '~antd/es/style/themes/default.less';*/

@layout-body-background: #f0f2f5;
@screen-md: 768px;
@screen-md-min: @screen-md;
@heading-color: rgba(0, 0, 0, 0.85);
@text-color-secondary: rgba(0, 0, 0, 0.45);
@font-size-base: 14px;
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  // margin: -24px;
  overflow: auto;
  background: @layout-body-background;
}

.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;
  :global(.ant-dropdown-trigger) {
    margin-right: 24px;
  }
}

.content {
  flex: 1;
  padding: 32px 0;
}

@media (min-width: @screen-md-min) {
  .container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }

  .content {
    padding: 32px 0 24px;
  }
}

.top {
  text-align: center;
}

.header {
  height: 44px;
  line-height: 44px;
  a {
    text-decoration: none;
  }
}

.logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}

.title {
  position: relative;
  top: 2px;
  color: @heading-color;
  font-weight: 600;
  font-size: 33px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.desc {
  margin-top: 12px;
  margin-bottom: 40px;
  color: @text-color-secondary;
  font-size: @font-size-base;
}

.main {
  width: 368px;
  margin: 0 auto;
  @media screen and (max-width: @screen-sm) {
    width: 95%;
  }

  .icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: @primary-color;
    }
  }

  .other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left;

    .register {
      float: right;
    }
  }

  :global {
    .antd-pro-login-submit {
      width: 100%;
      margin-top: 24px;
    }
  }
}

@primary-color: #1DA57A;