body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.ant-pro-global-header {
  transition: all 0.2s,background 0.2s;
  /* transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s; */
}

.ant-pro-global-header-logo {
  background-color: #001529;
  margin: 0 -16px;
  margin-top: -32px;
  margin-bottom: -32px;
  height: 56px;
}

.ant-pro-global-header-collapsed-button + .ant-pro-global-header-logo {
  background-color: #fff;
  margin: 0;
}

.ant-pro-global-header > .ant-pro-global-header-collapsed-button {
  color: #000;
  cursor: pointer;
}