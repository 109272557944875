.main {
	 display: flex;
	 width: 100%;
	 height: 100%;
	 padding-top: 16px;
	 padding-bottom: 16px;
	 overflow: auto;
	 background-color: #fff;
}
 .main .leftMenu {
	 width: 224px;
	 border-right: 1px solid #f0f0f0;
}
 .main .leftMenu :global .ant-menu-inline {
	 border: none;
}
 .main .leftMenu :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	 font-weight: bold;
} 
 .main .right {
	 flex: 1;
	 padding-top: 8px;
	 padding-right: 40px;
	 padding-bottom: 8px;
	 padding-left: 40px;
}
 .main .right .title {
	 margin-bottom: 12px;
	 color: rgba(0, 0, 0, .85);
	 font-weight: 500;
	 font-size: 20px;
	 line-height: 28px;
}
 