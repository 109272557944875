.splitView {
	 display: flex;
	 padding-top: 12px;
}
 .splitView .left {
	 min-width: 380px;
	 max-width: 480px;
}
 .splitView .right {
	 flex: 1;
	 padding-left: 104px;
}
 .splitView .right .avatar_title {
	 height: 22px;
	 margin-bottom: 8px;
	 font-weight: bold;
	 font-size: 14px;
	 line-height: 22px;
}
 .splitView .right .avatar {
	 width: 144px;
	 height: 144px;
	 margin-bottom: 12px;
	 overflow: hidden;
}
 .splitView .right .avatar img {
	 width: 100%;
}
 .splitView .right .button_view {
	 width: 144px;
	 text-align: center;
}
 